<template>
  <v-container fluid>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle text="Orders" class="ft d-flex flex-row ml-2" />
      <div>
        <v-text-field
          dense
          append-icon="search"
          light
          single-line
          v-model="search"
          style="min-width: 600px;"
          solo-inverted
          placeholder="Search by branch name, truck number, cheque number  ..."
          class="mt-2 ft font-size-sm black--text"
          hide-details
          flat
        />
      </div>
    </div>
    <div>
      <div class="d-flex flex-row flex-grow-1 pt-3 justify-space-between">
        <div class="d-flex flex-row" style="align-items: center">
          <v-btn
            v-ripple="{ class: 'primary--text' }"
            color="primary"
            class="ml-2 white--text ft"
            style="font-size: 12px"
            @click="open('add')"
          >
            New Order<v-icon size="20" color="white">mdi-plus</v-icon>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  $store.dispatch('orders/list', {
                    page: options.page,
                    itemsPerPage: options.itemsPerPage
                  })
                "
                fab
                class="ml-3"
                icon
                :loading="isListLoading"
                small
                color="primary"
              >
                <i class="material-icons-outlined font-size-md font-weight-bold"
                  >refresh</i
                >
              </v-btn>
            </template>
            <span class="ft font-weight-medium">Refresh</span>
          </v-tooltip>
        </div>
        <div class="d-flex flex-row" style="align-items: center;">
          <span class=" ft font-weight-medium font-size-sm ml-2 mr-4"
            >Filter by :</span
          >
          <v-chip-group v-model="query" mandatory class="mr-4 ">
            <v-chip
              class="box-shadow-light ml-1 ft font-weight-medium"
              style="font-size: 14px;"
              label
              active-class="primary--text"
              color="white"
              small
              v-for="(filter, index) in filters"
              :key="index"
              >{{ filter }}</v-chip
            >
          </v-chip-group>
        </div>
      </div>

      <h4
        style=" margin-left: 10px"
        class="blue-grey--text ft mt-2 font-size-sm"
      >
        List of orders
      </h4>
    </div>
    <v-row align="center">
      <v-col cols="12" sm="12" class="ml-3">
        <v-chip-group v-model="tag" show-arrows light class=" ft">
          <v-chip
            label
            color="white"
            active-class="primary--text"
            class="box-shadow-light ft"
            v-for="(tag, index) in tags"
            :value="tag"
            active
            :key="index"
          >
            <span class="font-weight-bold text-uppercases">{{ tag }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      style="margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="filteredOrders"
              item-key="order"
              :search.sync="search"
              :server-items-length="pagination.total"
              :options.sync="options"
              :loading="isListLoading"
              loading-text="Loading... Please wait"
              class=" box-shadow-light ft font-weight-medium"
            >
              <template #item.date="{item}">
                <span class="ft font-weight-bold">{{
                  item.date | timestamp
                }}</span>
              </template>
              <template #item.chequeId="{item}">
                <span
                  v-if="item.chequeId"
                  class="ft font-weight-bold text-sm "
                  >{{ item.chequeId.number }}</span
                >
              </template>
              <template #item.carId="{item}">
                <span class="ft font-weight-bold text-sm ">{{
                  item.carId ? item.carId.registrationNumber : "No Truck"
                }}</span>
              </template>
              <template #item.branchId="{item}">
                <span class="ft font-weight-bold text-sm ">{{
                  item.branchId.name
                }}</span>
              </template>
              <template #item.productId="{item}">
                <v-chip small label color="primary">
                  <span class="ft font-weight-bold">{{
                    item.productId.name
                  }}</span>
                </v-chip>
              </template>
              <template #item.quantity="{item}">
                <v-chip small color="primary" label>
                  <span class="ft font-weight-bold">{{ item.quantity }}</span>
                </v-chip>
              </template>
              <template #item.createdAt="{item}">
                <span class="ft font-weight-bold text-sm">{{
                  item.createdAt | timestamp("YYYY/MM/DD HH:MM")
                }}</span>
              </template>
              <template #item.isOrderReviewed="{item}">
                <v-chip
                  v-if="item.isOrderReviewed"
                  label
                  :color="item.isOrderReviewed ? '#9efb9e66' : '#f98c6b'"
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderReviewed
                        ? 'rgba(3, 91, 3, 0.83)'
                        : '#862c00'
                    }"
                    class="font-weight-bold"
                    >Reviewed</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  label
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span class="font-weight-bold">{{ "Not Reviewed " }}</span>
                </v-chip>
              </template>
              <template #item.isOrderCompleted="{item}">
                <v-chip
                  label
                  :color="
                    item.isOrderCompleted ||
                    (item.productDeliveries &&
                      item.productDeliveries
                        .filter(delivery => delivery.isDeliveryAccepted)
                        .reduce((acc, item) => (acc += item.quantity), 0) ===
                        item.quantity)
                      ? '#9efb9e66'
                      : 'rgb(247, 189, 172)'
                  "
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color:
                        item.isOrderCompleted ||
                        (item.productDeliveries &&
                          item.productDeliveries
                            .filter(delivery => delivery.isDeliveryAccepted)
                            .reduce(
                              (acc, item) => (acc += item.quantity),
                              0
                            ) === item.quantity)
                          ? 'rgba(3, 91, 3, 0.83)'
                          : 'rgb(238, 78, 0)'
                    }"
                    class="font-weight-bold"
                    >{{
                      item.isOrderCompleted ||
                      (item.productDeliveries &&
                        item.productDeliveries
                          .filter(delivery => delivery.isDeliveryAccepted)
                          .reduce((acc, item) => (acc += item.quantity), 0) ===
                          item.quantity)
                        ? "Completed"
                        : "Not Completed"
                    }}</span
                  >
                </v-chip>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="$can('update_order', 'ORDERS')"
                      small
                      @click.stop="open('edit', 'orders/details', item.id)"
                      color="primary"
                      :disabled="
                        item.isOrderCompleted ||
                          (item.productDeliveries &&
                            item.productDeliveries
                              .filter(delivery => delivery.isDeliveryAccepted)
                              .reduce(
                                (acc, item) => (acc += item.quantity),
                                0
                              ) === item.quantity)
                      "
                      fab
                      icon
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-sm">edit</i>
                    </v-btn>
                  </template>
                  <span class=" ft font-weight-medium text-sm"
                    >Edit Reviewed Order</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="$can('share_product_quantity', 'DELIVERY')"
                      :disabled="
                        item.isDeliveryGenerated || !item.isOrderReviewed
                      "
                      small
                      @click.stop="open('generate', 'orders/details', item.id)"
                      color="primary"
                      fab
                      icon
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-sm">dvr</i>
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium text-sm"
                    >Generate Deliveries</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      color="primary"
                      v-if="$can('share_product_quantity', 'DELIVERY')"
                      @click.stop="
                        open('details', 'deliveries/remainingQuantity', item.id)
                      "
                      :disabled="
                        !item.isDeliveryGenerated || item.isOrderCompleted
                      "
                      fab
                      icon
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-sm"
                        >call_split
                      </i>
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium text-sm"
                    >Share Remaining Quantity</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="$can('view_deliveries', 'DELIVERIES')"
                      @click="open('alert', 'orders/details', item.id)"
                      icon
                      fab
                      small
                      color="primary"
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-sm">
                        shopping_cart
                      </i>
                    </v-btn>
                  </template>
                  <span class=" ft font-weight-medium text-sm"
                    >View Deliveries</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="$can('delete_order', 'ORDERS')"
                      @click="open('delete', 'orders/details', item.id)"
                      icon
                      fab
                      small
                      color="primary"
                      v-on="on"
                    >
                      <i class="material-icons-outlined font-size-sm">
                        delete
                      </i>
                    </v-btn>
                  </template>
                  <span class=" ft font-weight-medium text-sm"
                    >Delete Order</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Order</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-select
                    :items="products"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Product"
                    ref="itemProduct"
                    class="mx-5 px-2 required"
                    v-model="itemProduct"
                    :rules="[rules.requiredProduct]"
                  >
                  </v-select>
                  <v-text-field
                    dense
                    outlined
                    label="Cheque Number"
                    ref="itemChequeNumber"
                    class="mx-5 px-2 required"
                    v-model="itemChequeNumber"
                    :rules="[rules.requiredChec]"
                  >
                  </v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Cheque Amount"
                    ref="itemCheckAmount"
                    class="mx-5 px-2 required"
                    v-model="itemChequeAmount"
                    :rules="[rules.requiredAmount]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Quantity"
                    ref="itemQuantity"
                    class="mx-5 px-2 required"
                    v-model="itemQuantity"
                    :rules="[rules.requiredQuant]"
                  >
                  </v-text-field>

                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    >-->
                  <!--                  <v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="itemRegion"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <!--                                <v-text-field-->
                  <!--                                        dense-->
                  <!--                                        outlined-->
                  <!--                                        label="Phone"-->
                  <!--                                        class="mx-5 px-2 required"-->
                  <!--                                        ref="phone"-->
                  <!--                                        v-model="itemPhone"-->
                  <!--                                        :rules="[rules.min]"-->
                  <!--                                >-->
                  <!--                                </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="itemLat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="itemLng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
                <v-row>
                  <v-select
                    :items="branches"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Branch"
                    ref="itemBranch"
                    class="mx-5 px-2 required"
                    v-model="itemBranch"
                    :rules="[rules.requiredBranch]"
                  ></v-select>
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  text
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NewOrderDialog
      :state="dialog('add')"
      @actions="close"
      @order="add"
      :loading="loading"
    />
    <EditOrderDialog
      :state="dialog('edit')"
      :loading="loading"
      :details="orderDetails"
      :pageLoading="isPageLoading"
      :currency="currency"
      @order="editOrder"
      @actions="close"
    />
    <OrderShareRemainingBottomSheet
      :state="dialog('details')"
      :loading="loading"
      :pageLoading="isPageLoading"
      :details="remainingQuantityDetails"
      @deliveries="generateDelivery"
      @actions="close"
    />
    <OrderDeliveriesBottomSheet
      :state="dialog('generate')"
      :details="orderDetails"
      :loading="loading"
      :pageLoading="isPageLoading"
      :currency="currency"
      @deliveries="generateDelivery"
      @actions="close"
    />
    <ViewDeliveriesBottomSheet
      :state="dialog('alert')"
      :pageLoading="isPageLoading"
      :details="orderDetails"
      :loading="loading"
      :currency="currency"
      @close="close"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
    <ConfirmationDialog
      type="delete"
      title="Delete Order"
      :state="dialog('delete')"
      @close="close"
      :loading="loading"
      @save="deleteOrder"
    />
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-vars */
import PageTitle from "../../../../components/PageTitle";
import DialogMixins from "../../../../mixins/DialogMixins";
import SnackBarMixins from "../../../../mixins/SnackBarMixins";
import { timestamp } from "../../../../filters/timestamp";
import * as axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "Orders",
  components: {
    PageTitle,
    NewOrderDialog: () => import(`./dialogs/NewOrderDialog`),
    EditOrderDialog: () => import(`./dialogs/EditOrderDialog`),
    OrderShareRemainingBottomSheet: () =>
      import(`./dialogs/OrderShareRemainingBottomSheet`),
    OrderDeliveriesBottomSheet: () =>
      import(`./dialogs/OrderDeliveriesBottomSheet`),
    ViewDeliveriesBottomSheet: () =>
      import(`./dialogs/ViewDeliveriesBottomSheet`),
    SnackBar: () => import(`../../../../components/SnackBar`),
    ConfirmationDialog: () =>
      import(`../../../../components/ConfirmationDialog`)
  },
  mixins: [DialogMixins, SnackBarMixins],
  computed: {
    ...mapGetters({
      loading: "getIsLoading",
      isPageLoading: "getIsPageLoading",
      isListLoading: "getIsListLoading",
      orders: "orders/getAllOrders",
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      pagination: "orders/getPagination",
      orderDetails: "orders/getOrderDetails",
      remainingQuantityDetails: "deliveries/getRemainingQuantity",
      pageLoading: "getIsPageLoading",
      currency: "settings/getSystemCurrency"
    }),
    filteredOrders() {
      return this.$store.getters["orders/getAllOrders"].filter(order => {
        return (
          order.branchId.name
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1 ||
          order?.carId?.registrationNumber
            ?.toLowerCase()
            .indexOf(this.search?.toLowerCase()) !== -1 ||
          order?.chequeId?.number
            ?.toLowerCase()
            .indexOf(this.search?.toLowerCase()) !== -1
        );
      });
    }
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Cheque No.", value: "chequeId" },
        { text: "Truck No.", value: "carId" },
        { text: "Branch", value: "branchId", align: "center" },
        { text: "Product", value: "productId", align: "center" },
        { text: "Qty (Lt)", value: "quantity", align: "center" },
        { text: "Order Review", value: "isOrderReviewed" },
        { text: "Order Completed", value: "isOrderCompleted" },
        { text: "Actions", value: "actions", align: "center" }
      ],
      progress: false,
      search: "",

      formHasErrors: false,

      dialogUpdate: false,
      description: "",
      products: [],
      branches: [],
      phone: "",
      lng: "",
      lat: "",

      product: "",
      chequeNumber: "",
      chequeAmount: 0,
      branch: "",
      quantity: "",
      itemId: "",
      itemChequeNumber: "",
      itemChequeAmount: "",
      itemQuantity: "",
      itemProduct: "",
      itemBranch: "",

      itemObjects: {
        chequeAmount: null,
        product: null,
        branch: null,
        chequeNumber: null,
        quantity: 0
      },
      tag: null,
      dialogDelete: false,
      region: "",
      location: "",
      orderInfo: {
        chequeId: {
          number: ""
        }
      },
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredChec: value =>
          !!value || value === null || "Cheque Number is required.",
        requiredAmount: value =>
          !!value || value === null || "Amount is Required.",
        requiredProduct: value =>
          !!value || value === null || "Product is Required.",
        requiredQuant: value =>
          !!value || value === null || "Quantity is Required.",
        requiredBranch: value =>
          !!value || value === null || "Branch is Required."
      },

      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      query: 0,
      filters: ["All", "Reviewed", "Completed"]
    };
  },

  filters: {
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    },
    timestamp
  },

  watch: {
    query(value) {
      this.$router
        .push({
          query: {
            filter: this.filters[value]?.toLowerCase()
          }
        })
        .catch(() => {});
    },
    "$route.query"(payload) {
      this.query = this.filters.findIndex(
        value => value?.toLowerCase() === payload?.filter
      );
      if (payload?.filter === "all") {
        this.$store.dispatch("orders/list", {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage
        });
        return;
      }
      // getting the current month filtering
      if (!this.tag) {
        this.$store.dispatch("orders/list", {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          ...payload
        });
      } else {
        this.$store.dispatch("orders/filterByMonth", {
          tag: moment(this.tag, "MMMM YYYY").format("YYYY-MM-DD"),
          page: 1,
          limit: this.pagination.limit,
          ...payload
        });
      }
    },
    options: {
      handler(value) {
        this.$store.dispatch("orders/list", {
          page: value.page,
          itemsPerPage: value.itemsPerPage,
          ...(this.$route.query && this.$route.query)
        });
      },
      deep: true
    },
    tag(value) {
      if (!value) {
        this.$store.dispatch("orders/list", {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          ...(this.$route.query && this.$route.query)
        });
        return;
      }
      const date = moment(value, "MMMM YYYY").format("YYYY-MM-DD");
      this.$store.dispatch("orders/filterByMonth", {
        tag: date,
        page: 1,
        limit: this.pagination.limit,
        ...(this.$route.query && this.$route.query)
      });
    }
  },

  created() {
    this.$store.dispatch("orders/list", {
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage
    });
    this.$store.dispatch("orders/branchList");
    this.$store.dispatch("orders/productList");
    this.tags = new Array(12).fill(0).map((item, index) =>
      moment()
        .month(index)
        .format("MMMM YYYY")
    );

    this.$store.dispatch("settings/systemCurrency");
    this.query = this.filters.findIndex(
      tag => tag.toLowerCase() === this.$route.query?.filter
    );
  },

  methods: {
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemChequeAmount = value.chequeId.amount;
      this.itemChequeNumber = value.chequeId.number;
      this.itemBranch = value.branchId.name;
      this.itemQuantity = value.quantity;
      this.itemProduct = value.productId.id;
      this.getProducts();
      this.getBranches();
    },
    getProducts() {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/products`
      })
        .then(response => {
          if (response.status === 200) {
            this.products = response.data;
          }
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    },

    newOrderDialog() {
      this.dialog = true;
      this.getProducts();
      this.getBranches();
    },
    deleteFunction(item) {
      this.dialogDelete = true;
      this.orderInfo = item;
    },

    getBranches() {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/branches`
      })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            this.branches = response.data;
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    },

    save() {
      this.progress = true;
      setTimeout(() => {
        axios({
          method: "put",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/orders/${this.itemId}`,
          data: {
            chequeAmount: this.itemChequeAmount,
            chequeNumber: this.itemChequeNumber,
            branchId: this.itemBranch,
            quantity: this.itemQuantity,
            productId: this.itemProduct
          }
        })
          .then(response => {
            //console.log(response);
            this.progress = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = "Order has been updated successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.getOrders(this.options.page, this.options.itemsPerPage);
            }
          })
          .catch(err => {
            if (err.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              this.progress = false;
              this.status = "warning";
              this.showSnackBar = true;
              this.message = "Failed to update this order.";
            }
          });
      }, 5000);
    },

    add(payload) {
      this.$store.dispatch("orders/createOrder", payload);
    },
    generateDelivery(payload) {
      this.$store.dispatch("orders/generateDeliveries", payload);
    },
    editOrder(payload) {
      this.$store.dispatch("orders/updateOrder", payload);
    },
    deleteOrder() {
      const order = this.$store.getters["orders/getOrderDetails"];

      this.$store.dispatch("orders/deleteOrder", {
        id: order?.id
      });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
